import React from "react";
import { motion } from "framer-motion";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};
export const ProjectItem = ({ project }) => {
  return (
    <motion.section
      variants={item}
      className="flex flex-1 flex-col items-center"
    >
      <div className="flex flex-col w-[90%]">
        <div className="flex flex-1 justify-center item-center p-4 pt-24">
          <img
            className="h-[100px] w-[100px] lg:h-[200px] lg:w-[200px] object-contain grayscale brightness-[10000%]"
            src={project?.logo}
          />
        </div>
        <h1 className="text-background text-shadow-title text-xl text-center lg:text-3xl">
          {project?.name}
        </h1>
        <span className=" text-background flex flex-1 justify-center items-center text-center text-shadow-subTitle text-lg lg:text-xl pb-7">
          {project?.title}
        </span>
        {project.description?.map((desc) => {
          return <p className=" text-white pb-2 text-lg font-thin">{desc}</p>;
        })}
        <p className="pt-10 text-background text-shadow-secondary-title text-lg lg:text-xl">
          SCREENSHOTS
        </p>
        <div className="flex flex-1 justify-around pt-5 pb-5 flex-col lg:flex-row items-center">
          {project.mobileScreenShots?.map((screenshot) => (
            <img
              className="h-[600px] rounded-[20px] mt-6 max-w-[440px]"
              src={screenshot}
            />
          ))}
        </div>
        <div className="flex flex-1 justify-around pt-5 pb-5 flex-col lg:flex-row">
          {project.webScreenShots?.map((screenshot) => (
            <img
              className="h-[260px] lg:h-[500px] w-[660px] rounded-xl  mt-6"
              src={screenshot}
            />
          ))}
        </div>
        <p className="pt-10 text-background text-shadow-secondary-title text-lg lg:text-xl">
          USED TECHNOLOGIES
        </p>
        <div className="flex flex-1 justify-around pt-5 pb-5 flex-col lg:flex-row">
          {project.technologies?.map((tech) => (
            <div className="flex justify-center flex-col items-center pb-2">
              <img
                className="h-[60px] w-[60px] object-contain grayscale brightness-[10000%]"
                src={tech.image}
              />
              <p className="text-white text-base p-3">{tech.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[1px] w-[80%] bg-divider rounded mt-12" />
    </motion.section>
  );
};
