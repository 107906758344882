import React from "react";
import { Button } from "../../../components/Button";
import HeroImage from "../../../images/heroImages/image_1.svg";
import Typewriter from "typewriter-effect";
import { motion } from "framer-motion";
import { leftVariants, rightVariants } from "../../../animations";
import HeroImageViewer from "../../../components/HeroImageViewer";
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t } = useTranslation();
  return (
    <motion.section
      className="min-h-screen w-full flex flex-col flex-1 lg:flex-row overflow-x-hidden overflow-y-hidden lg:p-10 p-5 pt-16"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.6 }}
      id="hero"
    >
      <motion.div
        className="flex flex-1 lg:justify-center flex-col relative z-[1000]"
        variants={leftVariants}
      >
        <div>
          <h1 className="text-white text-base lg:text-2xl">
            {t("Techbucket focuses on")}
          </h1>
          <span className="text-primary text-base pt-5 font-extralight lg:text-xl">
            <Typewriter
              options={{
                loop: true,
                autoStart: true,
                strings: [
                  t("customer satisfaction"),
                  t("business requirements"),
                  t("creative ideas"),
                ],
              }}
            />
          </span>
          <p className="text-lg font-thin text-white pb-5 lg:text-base">
            {t("We think carefully")}
          </p>
          <Button
            title={t("see more")}
            onClick={() => window.open("/projects")}
          />
        </div>
      </motion.div>
      <motion.div
        className="flex flex-1 lg:p-10 relative z-[1000] justify-center lg:items-center"
        variants={rightVariants}
      >
        <HeroImageViewer />
      </motion.div>
    </motion.section>
  );
};

export { Hero };
