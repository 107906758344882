import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOn, setIsOn] = useState(i18n.language == "de");

  const toggleSwitch = () => {
    i18n.changeLanguage(!isOn ? "de" : "en");
    setIsOn(!isOn);
  };

  return (
    <div
      className={`w-[80px] h-[40px] bg-white/40 flex  ${
        isOn ? "justify-end" : "justify-start"
      } rounded-[50px] p-1 cursor-pointer`}
      onClick={toggleSwitch}
    >
      <div
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          display: "flex",
        }}
      >
        <p>EN</p>
      </div>
      <motion.div
        className="h-[30px] w-[30px] bg-white rounded-[40px] flex justify-center items-center absolute"
        layout
        transition={spring}
      >
        <p style={{ fontWeight: "bold", color: "#1a1a1a" }}>
          {!isOn ? "EN" : "DE"}
        </p>
      </motion.div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
        }}
      >
        <p>DE</p>
      </div>
    </div>
  );
};

const spring = {
  type: "spring",
  stiffness: 700,
  damping: 30,
};
