import { createBrowserRouter } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeState";
import { Home, Projects } from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ThemeContextProvider>
        <Home />
      </ThemeContextProvider>
    ),
  },
  {
    path: "projects",
    element: (
      <ThemeContextProvider>
        <Projects />
      </ThemeContextProvider>
    ),
  },
]);
