import React, { useState } from "react";
import TechbucketLogo from "../../images/techbucket-logo.png";
import { Link } from "react-scroll";
import { AnimatePresence, motion } from "framer-motion";
import { LanguageSwitcher } from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { MenuButton } from "../MenuButton";

const variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const item = (index) => {
  return {
    open: {
      x: 0,
      transition: {
        delay: (index * 0.5) / 2,
      },
    },
    closed: { x: -1000 },
  };
};

const navItems = (t) => [
  {
    id: 1,
    name: "Home",
    to: "hero",
  },
  {
    id: 2,
    name: t("services"),
    to: "services",
  },
  {
    id: 3,
    name: t("partners & clients"),
    to: "partners",
  },
  {
    id: 4,
    name: t("projects"),
    to: "projects",
  },
];

const MobileNavBar = ({ isHomePage }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="flex-[0.4] flex justify-end lg:hidden">
      <MenuButton onClick={() => setMenuOpen(!isMenuOpen)} />
      <AnimatePresence>
        <motion.div
          animate={isMenuOpen ? "open" : "closed"}
          variants={variants}
          className={`${
            isMenuOpen ? "fixed" : "hidden"
          } h-[100%] top-16 right-0 bottom-0 left-0 bg-gradient-to-bl from-primary to-secondary z-[99999999999]`}
        >
          <div className="flex flex-[0.6] justify-around items-center flex-col h-[50%]">
            {navItems(t).map((navItem, index) => {
              if (!isHomePage && index <= 3 && index > 0) {
                return;
              }
              return (
                <motion.div
                  variants={item(index)}
                  whileTap={{ scale: 0.8 }}
                  key={index}
                >
                  <Link
                    className="text-xl cursor-pointer text-white"
                    activeClass="border-secondary text-primary border-r-0"
                    smooth
                    spy={true}
                    to={navItem.to}
                    onClick={() => {
                      if (index === 3) {
                        //then it is the projects route
                        window.open("/projects");
                      }
                      if (index == 0 && !isHomePage) {
                        window.close();
                      }
                      setMenuOpen(false);
                    }}
                  >
                    {navItem.name}
                  </Link>
                </motion.div>
              );
            })}
          </div>
          <motion.div
            variants={item(!isHomePage ? 1 : 4)}
            whileTap={{ scale: 0.8 }}
          >
            <div className="flex justify-center items-center">
              <LanguageSwitcher />
            </div>
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const NavBar = ({ isHomePage = true }) => {
  const { t } = useTranslation();
  return (
    <nav className="nav-bar-container">
      <a
        href="/"
        className="flex-[0.6] lg:flex-[0.2] flex justify-start items-start"
      >
        <img src={TechbucketLogo} className="nav-bar-logo" />
      </a>
      <div className="hidden flex-[0.6] justify-between lg:flex lg:flex-[0.8] pr-4">
        {navItems(t).map((navItem, index) => {
          if (!isHomePage && index <= 3 && index > 0) {
            return;
          }
          return (
            <div key={index}>
              <Link
                className="text-sm lg:text-lg cursor-pointer text-placeholder  hover:border-secondary hover:text-primary hover:border-r-0"
                activeClass="border-secondary text-primary border-r-0"
                smooth
                spy={true}
                onClick={() => {
                  if (index === 3) {
                    // projects route
                    window.open("/projects");
                  }
                  if (index == 0 && !isHomePage) {
                    window.close();
                  }
                }}
                //when projects route to should be empty since we use onCLick
                to={index === 3 ? "" : navItem.to}
              >
                {navItem.name}
              </Link>
            </div>
          );
        })}
        <LanguageSwitcher />
      </div>
      {/* mobile menu */}
      <MobileNavBar isHomePage={isHomePage} />
    </nav>
  );
};

export { NavBar };
