import React, { useRef } from "react";
import DesignImage from "../../../images/design.svg";
import GameDevelopment from "../../../images/game-development.svg";
import WebDevelopment from "../../../images/web-development.svg";
import MobileDevelopment from "../../../images/mobile-development.svg";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";

const services = (t) => [
  {
    title: t("mobile development"),
    image: MobileDevelopment,
  },
  {
    title: t("game development"),
    image: GameDevelopment,
  },
  {
    title: t("web development"),
    image: WebDevelopment,
  },
  {
    title: t("uiux design"),
    image: DesignImage,
  },
];

const Services = ({ ref }) => {
  const { t } = useTranslation();
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  const titleOpacity = useTransform(scrollYProgress, [0, 0.23, 0.5], [0, 0, 1]);

  const titleTranslateX = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5],
    [100, 50, 0]
  );

  const titleTranslateY = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5],
    [500, 200, 0]
  );

  return (
    <motion.section
      id="services"
      className="min-h-screen w-full flex flex-col flex-1 overflow-hidden p-5 lg:p-10 pt-16"
    >
      <motion.h1
        style={{ opacity: titleOpacity, translateY: titleTranslateX }}
        className="text-primary text-base lg:text-5xl"
      >
        {t("fast")} <br></br>
        {t("simple")} <br></br>
        {t("beautiful")}
      </motion.h1>
      <motion.p
        className="text-white text-lg font-thin pb-10 lg:text-xl"
        style={{
          opacity: titleOpacity,
          translateY: titleTranslateY,
        }}
      >
        {t("we help brands")}
        <br></br>
        {t("fast easy beautiful products")}
      </motion.p>
      <div className="grid grid-cols-1 grid-rows-1 lg:grid-cols-2 lg:grid-rows-2">
        {services(t).map((service, key) => (
          <motion.div
            key={key}
            className="flex flex-col justify-center items-center"
            style={{
              translateX: titleTranslateX,
              translateY: titleTranslateY,
              opacity: titleOpacity,
            }}
          >
            <img className=" h-1/3 w-1/2 object-contain" src={service.image} />
            <h3 className="text-primary font-extralight text-lg text-center lg:text-base">
              {service.title}
            </h3>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export { Services };
