import React, { useState } from "react";
import { Tab } from "./Tab";
import { useTabsContext } from "../../context/TabsState";

export const Tabs = () => {
  const [selectedTab, setSelectedTab] = useState("All");
  const { filterProjects } = useTabsContext();
  const onTabPressed = (category) => {
    setSelectedTab(category);
  };
  return (
    <div className="flex flex-1 flex-row mt-10 pt-5 justify-around items-center">
      <Tab
        category={"All"}
        isSelected={selectedTab === "All"}
        onTabPressed={() => {
          onTabPressed("All");
          filterProjects("All");
        }}
      />
      <Tab
        category={"Food"}
        isSelected={selectedTab === "Food"}
        onTabPressed={() => {
          onTabPressed("Food");
          filterProjects("Food");
        }}
      />
      <Tab
        category={"E-commerce"}
        isSelected={selectedTab === "E-commerce"}
        onTabPressed={() => {
          onTabPressed("E-commerce");
          filterProjects("E-commerce");
        }}
      />
      <Tab
        category={"Sport"}
        isSelected={selectedTab === "Sport"}
        onTabPressed={() => {
          onTabPressed("Sport");
          filterProjects("Sport");
        }}
      />
    </div>
  );
};
