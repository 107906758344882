import { useRef } from "react";
import "./Home.css";
import { NavBar, SideBar } from "../../components";
import { Hero, Partners, Services, Footer } from "../../sections";
import { useThemeContext } from "../../context/ThemeState";
import { useScroll } from "framer-motion";

function Home() {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  return (
    <div className="bg-background" ref={targetRef}>
      <NavBar scrollYProgress={scrollYProgress} />
      {/* <SideBar /> */}
      <div className="pt-5">
        <Hero />
        <Services />
        <Partners />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
