import React from "react";
import { useThemeContext } from "../../../context/ThemeState";
import TechbucketWhite from "../../../images/techbucket-white.png";

const Footer = () => {
  const { isDarkMode } = useThemeContext();
  return (
    <section className="gradient-background-placeholder-bottom">
      <div className="p-3 flex flex-row justify-between items-end flex-1">
        <div className="flex flex-col justify-center">
          <p className="text-white font-bold text-xs lg:text-lg lg:pb-4">
            Techbucket Ltd.
          </p>
          <p className="text-white font-light text-xs lg:text-lg lg:pb-4">
            Eptinerstrasse 28, 4052 Basel, Switzerland
          </p>
          <p className="text-white font-light text-xs lg:text-lg lg:pb-4">
            Business park street, 11831 Amman, Jordan
          </p>
          <br />
          <p className="text-white font-light text-xs lg:text-lg lg:pb-4">
            Mississauga, Ontario L5N 8G6, Argentina
          </p>
          <br />
          <p className="text-white font-light text-xs lg:text-lg lg:pb-2">
            +41 77 40 440 19
          </p>
          <p className="text-white font-light text-xs lg:text-lg lg:pb-2">
            info@techbucket.ch
          </p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={TechbucketWhite} className="nav-bar-logo" />
          <p className="text-white font-light text-xs lg:text-lg text-center">
            © {new Date().getFullYear()} Techbucket. All rights reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export { Footer };
