import React from "react";
import { motion } from "framer-motion";

const Button = ({ title, onClick }) => {
  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className=" bg-primary py-2 px-2 border-0 rounded-md text-white cursor-pointer uppercase"
    >
      {title}
    </motion.button>
  );
};

export { Button };
