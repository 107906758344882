import React, { useEffect, useState } from "react";
import "./Projects.css";
import { ProjectItem } from "../../components/ProjectItem";
import { NavBar, SideBar } from "../../components";
import { Tabs } from "../../components/Tabs";
import { TabStateProvider, useTabsContext } from "../../context/TabsState";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const RenderedProjects = () => {
  const { t } = useTranslation();
  const { projects } = useTabsContext();

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      {projects(t).map((project) => (
        <ProjectItem project={project} />
      ))}
    </motion.div>
  );
};

export const Projects = () => {
  return (
    <TabStateProvider>
      <div className="app">
        <NavBar isHomePage={false} />
        <Tabs />
        <RenderedProjects />
      </div>
    </TabStateProvider>
  );
};
