import React from "react";
import Lottie from "lottie-react";
import HeroAnimation from "../../utils/lottie-animations/hero.json";

const HeroImageViewer = () => {
  return (
    <div>
      <Lottie
        style={{
          height: "100%",
          width: "100%",
        }}
        animationData={HeroAnimation}
      />
      ;
    </div>
  );
};

export default HeroImageViewer;
