import React from "react";
import { motion } from "framer-motion";
import "./Tab.css";
//text-lg md:text-xl lg:text-2xl
export const Tab = ({ category, isSelected, onTabPressed }) => {
  return (
    <motion.div
      className="cursor-pointer flex justify-center items-center flex-col"
      onClick={() => onTabPressed(category)}
      whileHover={{ scale: 1.2 }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <motion.p
        className={`tab-title uppercase text-center ${
          isSelected
            ? "text-lg md:text-xl lg:text-2xl"
            : "text-lg md:text-base lg:text-xl"
        }`}
        style={{ color: isSelected ? "#b715cb" : "#1a1a1a" }}
      >
        {category}
      </motion.p>
      {isSelected && (
        <motion.div
          layoutId="underline"
          // transition={{
          //   type: "spring",
          //   bounce: 0.25,
          //   duration: 0.5,
          // }}
          className="h-[10px] w-[10px] rounded-3xl bg-primary border-primary shadow-[0_0_2px_#b715cb,inset_0_0_2px_#b715cb,0_0_5px_#b715cb,0_0_15px_#b715cb,0_0_30px_#b715cb]"
        />
      )}
    </motion.div>
  );
};
