import React, { useRef } from "react";
import ApptiveLogo from "../../../images/apptive-logo.png";
import AbuLogo from "../../../images/logoabu.png";
import CS2 from "../../../images/cs2logo.png";
import FC from "../../../images/fc.png";
import FS from "../../../images/fs.svg";
import Babsy from "../../../images/babsy-logo.png";
import DioMio from "../../../images/diomio.png";
import Netztech from "../../../images/netztech.png";
import { motion, useScroll, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";

const partnersLogos = [
  ApptiveLogo,
  FC,
  AbuLogo,
  CS2,
  FS,
  Babsy,
  DioMio,
  Netztech,
];

const Partners = () => {
  const targetRef = useRef(null);
  const { t } = useTranslation();
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "end end"],
  });

  const titleOpacity = useTransform(scrollYProgress, [0, 0.23, 0.5], [0, 0, 1]);

  const titleTranslateX = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5],
    [100, 50, 0]
  );

  const titleTranslateY = useTransform(
    scrollYProgress,
    [0, 0.25, 0.5],
    [500, 200, 0]
  );

  return (
    <motion.section
      className="min-h-screen w-full flex flex-col flex-1 overflow-hidden lg:p-10 p-5 pt-16"
      ref={targetRef}
      id="partners"
    >
      <motion.h1
        className="text-primary text-base lg:text-5xl "
        style={{ opacity: titleOpacity, translateY: titleTranslateX }}
      >
        {t("cross-industry")}
      </motion.h1>
      <motion.p
        className="text-white text-lg font-thin pb-10 lg:text-xl"
        style={{
          opacity: titleOpacity,
          translateY: titleTranslateY,
        }}
      >
        {t("focusing on the user")}
        <br></br>
        {t("enables us to")}
      </motion.p>
      <div className="grid grid-cols-2 grid-row-4 gap-2 lg:grid-col-4 lg:grid-row-2">
        {partnersLogos.map((logo, key) => (
          <motion.div
            key={key}
            className="flex  flex-1 justify-center items-center"
            style={{
              opacity: titleOpacity,
              translateY: titleTranslateY,
            }}
          >
            <img
              src={logo}
              className="h-52 w-52 object-contain grayscale brightness-[100000%] p-5"
            />
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export { Partners };
