import React from "react";
export const MenuButton = ({ onClick }) => {
  return (
    <svg
      className="auto w-5 h-5 mr-4 lg:hidden cursor-pointer"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 17 14"
      onClick={onClick}
    >
      <path
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1h15M1 7h15M1 13h15"
      />
    </svg>
  );
};
