import DioMio from "../images/diomio.png";
import Babsy from "../images/babsy-logo.png";
import Netztech from "../images/netztech.png";
import Apptive from "../images/apptive-logo.png";
import ReactNativeTech from "../images/technologies/reactjs.png";
import SenchaTech from "../images/technologies/sencha.png";
import VueJsTech from "../images/technologies/vue.png";
import LumenTech from "../images/technologies/lumen.svg";
import { createContext, useContext, useState } from "react";
import ApptiveScreenshot from "../images/screenshots/apptive.png";
import ApptiveScannerScreenshot from "../images/screenshots/apptive_scanner.png";
import DioMioMobileScreenshot from "../images/screenshots/dio_mio.png";
import DioMioWebScreenshot from "../images/screenshots/diomio_desktop.png";
import BabsyMobile from "../images/screenshots/babsy.png";
import NetztechMobile from "../images/screenshots/netztech.png";

const projects = (t) => [
  {
    id: 1,
    name: "DIO MIO",
    category: "Food",
    logo: DioMio,
    title: t("dioMio.title"),
    description: [
      t("dioMio.description.1"),
      t("dioMio.description.2"),
      t("dioMio.description.3"),
      t("dioMio.description.4"),
      t("dioMio.description.5"),
      t("dioMio.description.6"),
      t("dioMio.description.7"),
    ],
    mobileScreenShots: [DioMioMobileScreenshot],
    webScreenShots: [DioMioWebScreenshot],
    technologies: [
      {
        image: ReactNativeTech,
        title: "React Native",
      },
      {
        image: VueJsTech,
        title: "VueJS",
      },
      {
        image: LumenTech,
        title: "Lumen (PHP)",
      },
    ],
  },

  {
    id: 2,
    name: "BABSY",
    category: "Lifestyle",
    logo: Babsy,
    title: t("babsy.title"),
    description: [
      t("babsy.description.1"),
      t("babsy.description.2"),
      t("babsy.description.3"),
      t("babsy.description.4"),
      t("babsy.description.5"),
      t("babsy.description.6"),
    ],
    mobileScreenShots: [BabsyMobile],
    technologies: [
      {
        image: ReactNativeTech,
        title: "React Native",
      },
      {
        image: LumenTech,
        title: "Lumen (PHP)",
      },
    ],
  },
  {
    id: 3,
    name: "NETZTECH",
    category: "E-commerce",
    logo: Netztech,
    title: t("netztech.title"),
    description: [
      t("netztech.description.1"),
      t("netztech.description.2"),
      t("netztech.description.3"),
    ],
    mobileScreenShots: [NetztechMobile],
    technologies: [
      {
        image: ReactNativeTech,
        title: "React Native",
      },
    ],
  },
  {
    id: 4,
    name: "APPTIVE",
    logo: Apptive,
    category: "Sport",
    title: t("apptive.title"),
    description: [
      t("apptive.description.1"),
      t("apptive.description.2"),
      t("apptive.description.3"),
      t("apptive.description.4"),
      t("apptive.description.5"),
      t("apptive.description.6"),
      t("apptive.description.7"),
      t("apptive.description.8"),
      t("apptive.description.9"),
      t("apptive.description.10"),
    ],
    mobileScreenShots: [ApptiveScreenshot, ApptiveScannerScreenshot],
    webScreenShots: [],
    technologies: [
      {
        image: ReactNativeTech,
        title: "React Native",
      },
      {
        image: LumenTech,
        title: "Lumen (PHP)",
      },
      {
        image: ReactNativeTech,
        title: "ReactJS",
      },
      {
        image: SenchaTech,
        title: "Sencha Ext JS",
      },
    ],
  },
];

const TabsContext = createContext();

export const TabStateProvider = ({ children }) => {
  const [state, setState] = useState({ projects: (t) => [...projects(t)] });

  const value = {
    ...state,
    filterProjects: (category) => {
      if (category === "All") {
        setState({ projects: (t) => [...projects(t)] });
        return;
      }
      let filteredProjects = projects.filter(
        (project) => project.category === category
      );
      setState({ projects: filteredProjects });
    },
    reset: () => setState({ projects: (t) => [...projects(t)] }),
  };

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
};

export const useTabsContext = () => useContext(TabsContext);
